import React from "react"
import Knight from "../components/images/knight"
import Logotype from "../components/images/logotype"
import ChessPhoneMock from "../components/images/chess-phone-mock"

// import { Link } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"

const ChessTrackerAppPage = () => (
  <Layout noHeader noFooter>
    <SEO
      title="Chess Tracker App"
      description="A simple app to track, log, and analyze your chess moves available on iOS and Android"
    />
    <div className="bg-chess-blue min-h-screen w-screen text-white font-heebo py-2 px-4">
      <div className="container mx-auto h-full flex flex-row items-center flex-wrap-reverse lg:flex-nowrap">
        <div className="flex flex-col justify-end h-full w-full lg:w-8/12 lg:my-16 lg:mr-24">
          <div className="flex flex-row justify-end items-baseline">
            <div className="w-12">
              <Knight />
            </div>
            <div className="font-bold text-2xl lg:text-4xl text-right">
              CHESS TRACKER
            </div>
          </div>
          <div
            className="text-base lg:text-xl tracking-widest text-right mb-8"
            style={{ marginRight: "-.6em" }}
          >
            SIMPLE CHESS MOVE LOGGER
          </div>
          <div className="font-bold text-2xl text-center lg:text-right lg:text-4xl">
            TRACK YOUR WAY TO CHECKMATE
          </div>
          <p className="my-8 lg:my-16">
            The key to upping your chess game is study. The Chess Tracker app
            makes it easy to record your chess matches and revist them whenever
            you want.{" "}
          </p>
          <div className="flex lg:flex-row lg:space-x-4 lg:space-y-0 flex-col space-y-4 mb-16  items-center lg:items-start">
            <div
              onClick={() =>
                (window.location.href =
                  "https://apps.apple.com/us/app/chess-tracker/id1546469808")
              }
              className="cursor-pointer rounded px-2 py-4 w-48 bg-white flex items-center justify-center text-chess-blue"
            >
              Download for iPhone
            </div>
            <div
              onClick={() => alert("coming soon")}
              className="cursor-pointer rounded px-2 py-4 w-48 border-2 border-white flex items-center justify-center text-white"
            >
              Download for Android
            </div>
          </div>
          <div>
            <div className="lg:text-xl tracking-widest">MADE IN RVA BY</div>
            <div className="lg:w-1/2">
              <Logotype />
            </div>
          </div>
        </div>
        <div className="w-full lg:w-1/2 flex items-center justify-center">
          <div className="w-full overflow-hidden">
            <ChessPhoneMock />
          </div>
        </div>
      </div>
    </div>
  </Layout>
)

export default ChessTrackerAppPage
